<template>
  <highcharts
    v-if="chartOptions"
    ref="chart"
    type="chart"
    :options="chartOptions"
  />
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true
    },
    series: {
      type: Array,
      default: () => [],
      required: true
    },
    categories: {
      type: Array,
      default: () => [],
      required: true
    },
    height: {
      type: Number,
      default: 600,
      required: true
    }
  },
  data() {
    return {
      chartOptions: null
    };
  },

  computed: {
    ...mapGetters(['isFetchingCycles'])
  },

  watch: {
    isFetchingCycles(value) {
      if (value) this.$refs.chart.chart.showLoading();
      else this.$refs.chart.chart.hideLoading();
    }
  },

  mounted() {
    this.setChartOptions();
  },

  methods: {
    setChartOptions() {
      this.chartOptions = {
        chart: {
          type: 'bar',
          height: this.height,
          scrollablePlotArea: {
            minHeight: this.categories.length * 100,
            scrollPositionY: 0
          }
        },
        title: {
          text: 'Steps Completion Percentage'
        },

        xAxis: {
          categories: this.categories,
          title: {
            text: 'Steps'
          },
          gridLineWidth: 1,
          lineWidth: 0
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Average Steps Completion'
          },
          labels: {
            overflow: 'justify'
          },
          gridLineWidth: 0
        },
        tooltip: {
          valueSuffix: ' %'
        },
        legend: {
          verticalAlign: 'top',
          borderWidth: 1,
          itemStyle: {
            fontWeight: 'normal',
            fontSize: '10px'
          },
          borderColor: '#e4e4e4',
          backgroundColor: '#f1f1f1'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: '{y} %'
            },
            groupPadding: 0.1
          }
        },
        credits: {
          enabled: false
        },
        series: this.series
      };
    }
  }
};
</script>
